import { useAPI } from '@capturi/api-utils'
import LogoText_Positive from '@capturi/assets/images/logo/capturi_w-text_pos.svg'
import {
  Dashboard,
  DashboardPublicContext,
  DashboardView,
  dashboardsAPI,
  useOnlyWeekdaysToggle,
} from '@capturi/dashboard'
import {
  FilterPeriodProvider,
  FilterPeriodSelectContainer,
  PeriodDefinitions,
} from '@capturi/filters'
import { ErrorBoundaryWithFallbackComponent } from '@capturi/react-utils'
import request from '@capturi/request'
import { Button, Description, PageLoader } from '@capturi/ui-components'
import {
  Box,
  BoxProps,
  ButtonGroup,
  Fade,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Tooltip,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import qs from 'query-string'
import React, { Suspense, useRef } from 'react'
import { createRoot } from 'react-dom/client'
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md'
import { VscTextSize } from 'react-icons/vsc'
import { useEffectOnce, useFullscreen, useIdle, useToggle } from 'react-use'
import screenfull from 'screenfull'
import { SWRConfig, SWRConfiguration } from 'swr'

import CapturiThemeProvider from './components/CapturiThemeProvider'
import HttpErrorBoundaryFallback from './components/HttpErrorBoundaryFallback'
import NotFoundPage from './components/NotFoundPage'
import ScaleFontSizeTooltip from './components/ScaleFontSizeTooltip'
import { withPeriodSyncToSearchParams } from './filters/filter-period-enhancers'
import useFullscreenNudge from './hooks/useFullscreenNudge'
import { LanguageProvider } from './i18n/LanguageProvider'
import analytics, { initAnalytics } from './utils/analytics'
import { DashboardPublicEvent, logEvent } from './utils/events'

initAnalytics()

const searchParams = new URLSearchParams(window.location.search)
const accessKey = searchParams.get('accessKey')

const FilterPeriodProviderWithSync =
  withPeriodSyncToSearchParams(FilterPeriodProvider)

if (accessKey == null) {
  // TODO: Show an error screen
  // eslint-disable-next-line no-console
  console.warn('"accessKey" in url required')
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 5,
      refetchOnWindowFocus: true,
    },
  },
})

const swrSettings: SWRConfiguration = {
  fetcher: (url: string) => {
    let u = url
    // Prefix url with `public/`
    if (!url.startsWith('public/')) {
      u = `public/${url}`
    }

    // Append `accessKey` to url search params
    u = qs.stringifyUrl({
      url: u,
      query: {
        key: accessKey,
      },
    })

    return request.get(u, {
      headers: {
        /**
         * Custom header to let our request instance know that
         * auth tokens should not be validated before requests.
         * TODO: consider a better way to achieve this
         * */
        'capturi-anonymous': 'true',
      },
    })
  },

  errorRetryCount: 5,
  revalidateOnFocus: true,
}

const FullScreenView: React.FC<BoxProps> = (props) => (
  <Box w="100vw" h="100vh" bg="white" {...props} />
)

const App: React.FC = () => {
  const api = dashboardsAPI.dashboards
  const { data: dashboard, error } = useAPI<Dashboard>(
    accessKey ? api.getByAccessKey(accessKey) : null,
    {
      refreshInterval: 60_000,
    },
  )
  const [, PlotOnlyWeekdaysToggle] = useOnlyWeekdaysToggle()

  const isIdle = useIdle(3_000)
  const documentRef = useRef<HTMLElement>(document.body)
  const [show, toggleFullscreen] = useToggle(false)
  const isFullscreen = useFullscreen(documentRef, show, {
    onClose: () => {
      toggleFullscreen(false)
      logEvent(DashboardPublicEvent.LeaveFullscreen)
    },
  })

  const closeNudgeToast = useFullscreenNudge({
    isFullscreen,
    delay: 5000,
    onGoFullscreen: () => toggleFullscreen(true),
  })

  const onToggleFullScreen = (): void => {
    if (show) {
      logEvent(DashboardPublicEvent.LeaveFullscreen)
    } else {
      logEvent(DashboardPublicEvent.EnterFullscreen, {
        width: window.screen.availWidth,
        height: window.screen.availHeight,
      })
    }
    toggleFullscreen()
    closeNudgeToast()
  }

  if (error || !dashboard) {
    return <NotFoundPage />
  }

  const fullscreenLabel = isFullscreen
    ? t`Exit fullscreen mode`
    : t`View in fullscreen mode`
  return (
    <FilterPeriodProviderWithSync defaultPeriod={PeriodDefinitions.Last30Days}>
      <Flex direction="column" height="100%" width="100%">
        <Box as="header" pl={2} pr={2} pt={2} flex="0 0 40px">
          <Fade in={isIdle}>
            <Icon as={LogoText_Positive} h={8} w="auto" float="right" />
          </Fade>
          <Box pt={1}>
            <Heading
              as="h1"
              display="inline"
              fontSize={['xl', null, '2xl', '3xl']}
            >
              {dashboard.title}
            </Heading>
            {dashboard.description && (
              <Description as="span" fontSize={['md', null, null, 'lg']} pl={2}>
                {dashboard.description}
              </Description>
            )}
          </Box>
          <Box position="absolute" top={2} right={2}>
            <Fade in={!isIdle}>
              <HStack
                spacing={4}
                boxShadow={'-14px 0px 12px -5px white'}
                bg="white"
              >
                <Flex align="center" px={4}>
                  <PlotOnlyWeekdaysToggle
                    onChange={(e) =>
                      logEvent(DashboardPublicEvent.TogglePlotOnlyWeekdays, {
                        onlyWeekdays: e.target.checked,
                        view: 'toolbar',
                      })
                    }
                  />
                </Flex>
                <ButtonGroup size="sm">
                  <ScaleFontSizeTooltip>
                    <IconButton
                      aria-label={t`Adjust font size`}
                      icon={<Icon as={VscTextSize} boxSize={4} />}
                    />
                  </ScaleFontSizeTooltip>
                </ButtonGroup>
                <ButtonGroup size="sm">
                  <Box>
                    <FilterPeriodSelectContainer
                      onSelectPeriod={(value) =>
                        logEvent(DashboardPublicEvent.SetDatePeriod, {
                          name: value.name,
                          dayCount: value.create(new Date()).days(),
                        })
                      }
                      usePortal
                    />
                  </Box>
                  {screenfull.isEnabled && (
                    <Tooltip label={fullscreenLabel}>
                      <Button
                        primary={!isFullscreen}
                        secondary={isFullscreen}
                        leftIcon={
                          <Icon
                            as={isFullscreen ? MdFullscreenExit : MdFullscreen}
                            boxSize={5}
                          />
                        }
                        onClick={onToggleFullScreen}
                      >
                        {isFullscreen ? (
                          <Trans>Leave fullscreen mode</Trans>
                        ) : (
                          <Trans>Fullscreen mode</Trans>
                        )}
                      </Button>
                    </Tooltip>
                  )}
                </ButtonGroup>
              </HStack>
            </Fade>
          </Box>
        </Box>
        <Box as="main" flex={1} w="100%">
          <DashboardPublicContext.Provider value={true}>
            <DashboardView dashboard={dashboard} />
          </DashboardPublicContext.Provider>
        </Box>
      </Flex>
    </FilterPeriodProviderWithSync>
  )
}
const AppContainer: React.FC = () => {
  useEffectOnce(() => {
    if (accessKey == null) return
    const intervalId = setInterval(() => {
      analytics.event('public_dashboard_alive')
    }, 1800000)

    return () => {
      clearInterval(intervalId)
    }
  })

  if (accessKey == null) {
    return <NotFoundPage />
  }

  return (
    <ErrorBoundaryWithFallbackComponent
      FallbackComponent={HttpErrorBoundaryFallback}
    >
      <FullScreenView>
        <React.Suspense fallback={<div />}>
          <App />
        </React.Suspense>
      </FullScreenView>
    </ErrorBoundaryWithFallbackComponent>
  )
}

const container = document.getElementById('app-root')
// biome-ignore lint/style/noNonNullAssertion: <explanation>
const root = createRoot(container!)
root.render(
  <CapturiThemeProvider>
    <QueryClientProvider client={queryClient}>
      <SWRConfig value={swrSettings}>
        <LanguageProvider>
          <Suspense fallback={<PageLoader />}>
            <AppContainer />
          </Suspense>
        </LanguageProvider>
      </SWRConfig>
    </QueryClientProvider>
  </CapturiThemeProvider>,
)
